<template>
  <div class="mescroll" ref="mescroll">
    <div class="content_views">
      <router-view></router-view>
    </div>
    <div class="footer flex-row">
      <van-tabbar v-model="active" :route="true" @change="changeBar">
        <van-tabbar-item icon="wap-home-o" to="/desktop/principal"
          >首页</van-tabbar-item
        >
        <van-tabbar-item icon="user-o" to="/desktop/personal-center"
          >个人中心</van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import MeScroll from "mescroll.js";
import "mescroll.js/mescroll.min.css";
export default {
  components: {},
  props: {},
  data() {
    return {
      currentRoute: "",
      active: 0,
      back: 0,
       mescroll: null,
    };
  },
  created() {},

  mounted() {
    // this.createMeScroll();
  },
  watch: {},
  methods: {
      createMeScroll() {
      this.mescroll = new MeScroll(this.$refs.mescroll, {
        // 在mounted生命周期初始化mescroll,以确保您配置的dom元素能够被找到.
        down: {
          auto: false, // 是否在初始化完毕之后自动执行下拉回调callback; 默认true
          callback: this.downCallback, // 下拉刷新的回调
        },
      });
    },
    downCallback() {
      console.log("自动下拉刷新 ");
      setTimeout(() => {
        this.$nextTick(() => {
          this.mescroll.endSuccess(); // 结束下拉刷新,无参
          window.location.reload();
        });
      }, 500);
    },
    changeBar(i) {
      this.active = i;
    },
  },
  computed: {},
};
</script>
<style scoped lang='scss'>
.mescroll {
  width: 100%;
  height: 100%;
  .content_views {
    width: 100%;
    // flex: 1;
    height: 92%;
    background-color: #f3f3f3;
  }
  .footer {
    justify-content: space-between;
    width: 100%;
    height: 8%;
    /deep/ .van-tabbar {
      padding-bottom: 0;
      height: 8%;
      padding-top: 1%;
      box-sizing: border-box;
      border-top:1px solid #ecedee ;
    }
  }
}
</style>