<template>
  <div id="app">
    
      <router-view />
  </div>
</template>
<script>
import { removeStore, getStore } from "@/utils/store";

export default {
  data() {
    return {
      crrentRoute: "",
      prevRoute: "",
      showBindBar: false,
      isLoading: false,
    };
  },

  created() {
  },
  mounted() {
    // 添加 popstate 事件监听
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", this.onPopState, false);
  },
  beforeDestroy() {
    // 在组件销毁前，移除 popstate 事件监听
    window.removeEventListener("popstate", this.onPopState);
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler: function (n, o) {
        // console.log('oo',n,o)
        this.crrentRoute = n.name;
        this.prevRoute = o?.name;
        if (this.crrentRoute === "principal") {
          window.history.pushState(null, null, window.location.href);
        }
      },
    },
  },
  methods: {
    onPopState(e) {
      // 监听到浏览器回退事件

      // 直接进入页面受权后的返回
      if (getStore({ name: "isLogin" })) {
        window.history.go(-3);
        removeStore({ name: "isLogin" });
        return;
      }

      // 点击登录后的返回
      if (!this.prevRoute && this.crrentRoute === "personal-center") {
        this.$router.push("/desktop/principal");
      }
      // 首页的返回
      if (this.crrentRoute === "principal") {
        window.history.pushState(null, null, window.location.href);
      }
    },
  },
};
</script>
<style scoped lang='scss'>
#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /deep/ .van-pull-refresh {
    height: 100%;
    width: 100%;
  }
}
</style>
