<template>
  <div ref="mescroll" class="mescroll">
    <div class="nav_bar">
      <van-nav-bar left-arrow @click-left="onClickLeft" :left-text="title" />
    </div>
    <div class="page_container">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import MeScroll from "mescroll.js";
import "mescroll.js/mescroll.min.css";
export default {
  components: {},
  props: {},
  data() {
    return {
      title: "",
      mescroll: null,
    };
  },
  created() {},
  mounted() {
    // this.createMeScroll();
  },
  methods: {
    createMeScroll() {
      this.mescroll = new MeScroll(this.$refs.mescroll, {
        // 在mounted生命周期初始化mescroll,以确保您配置的dom元素能够被找到.
        down: {
          auto: false, // 是否在初始化完毕之后自动执行下拉回调callback; 默认true
          callback: this.downCallback, // 下拉刷新的回调
        },
      });
    },
    downCallback() {
      console.log("自动下拉刷新 ");
      setTimeout(() => {
        this.$nextTick(() => {
          this.mescroll.endSuccess(); // 结束下拉刷新,无参
          window.location.reload();
        });
      }, 500);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler: function (n) {
        this.title = n.meta.title;
      },
    },
  },
};
</script>
<style scoped lang='scss'>
.mescroll {
  width: 100%;
  height: 100%;
  .nav_bar {
    height: 7%;
    /deep/ .van-nav-bar {
      height: 100%;
      background-color: #e4e8f1;
    }
    /deep/ .van-nav-bar__content {
      height: 100%;
    }
    /deep/ .van-nav-bar__arrow {
      font-size: 26px;
    }
    /deep/ .van-nav-bar__text {
      font-size: 18px;
      color: #333;
    }
  }
  .page_container {
    width: 100%;
    height: 93%;
    background-color: #f3f3f3;
  }
}
</style>