import config from '@/config'



export const setStore = params => {
  const { name, content, type } = params
  const obj = {
    v: config.version,
    datetime: new Date().getTime(),
    dataType: typeof content,
    content,
    type
  }
  if (type) window.sessionStorage.setItem(name, JSON.stringify(obj))
  else window.localStorage.setItem(name, JSON.stringify(obj))
}

export const getStore = params => {
  const { name, debug } = params
  let obj = {}
  let content
  let session = window.sessionStorage.getItem(name)
  obj = session ? session : window.localStorage.getItem(name)
  if (isNull(obj)) return obj
  if (debug) {
    return obj
  }

  obj = JSON.parse(obj)

  if (obj.dataType === 'string') {
    content = obj.content
  } else if (obj.dataType === 'number') {
    content = Number(obj.content)
  } else if (obj.dataType === 'boolean') {
    // eslint-disable-next-line no-eval
    content = eval(obj.content)
  } else if (obj.dataType === 'object') {
    content = obj.content
  }
  return content
}


// 清除缓存
export const removeStore = params => {
  const { name } = params
  window.localStorage.removeItem(name)
  window.sessionStorage.removeItem(name)
}

export function isNull(val) {
    if (typeof val === 'boolean') {
      return false
    }
    if (typeof val === 'number') {
      return false
    }
    if (val instanceof Array) {
      if (val.length === 0) return true
    } else if (val instanceof Object) {
      if (JSON.stringify(val) === '{}') return true
    } else {
      if (val === 'null' || val === null || val === 'undefined' || val === undefined || val === '') return true
      return false
    }
    return false
  }


