import Layout from '@/components/Layout'
import NavBar from '@/components/navBar'
export default [
    /**
   * /desktop/principal   首页
   * /desktop/personal-center    个人中心
   * /assembly/register    会议信息
   * /assembly/registered-record  报名记录
   * /assembly/bind-user    用户绑定
   * /user-signin  用户签到
   * /register   报名
   * 
   */
  // 首页
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "desktop" */ '@/pages/HomeView.vue')
  },

  // 主页
  {
    path: '/desktop',
    name: 'desktop',
    component: Layout,
    redirect: '/desktop/principal',
    children: [
      {
        path: 'principal',
        name: 'principal',
        meta:{
          title:'首页',
          requiresAuth: true

        },
        component: () => import(/* webpackChunkName: "desktop" */ '@/pages/desktop/pricipal.vue')
      },
      {
        path: 'personal-center',
        name: 'personal-center',
        meta: {
          title: '个人中心',
          requiresAuth: true

        },
        component: () => import(/* webpackChunkName: "desktop" */ '@/pages/desktop/personalCenter.vue')
      }
      
    ]
  },
  // 带返回条的页面
  {
    path: '/assembly',
    name: 'assembly',
    component:NavBar,
    children: [
       // 报名
       {
        path: 'register',
        name: 'register',
        meta:{
          title:'会议信息',
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "assembly" */ '@/pages/assembly/register.vue')
      },
       // 已报名列表
       {
        path: 'registered-record',
        name: 'registered-record',
        meta:{
          title:'报名记录',
          requiresAuth: true

        },
        component: () => import(/* webpackChunkName: "assembly" */ '@/pages/assembly/registeredRecord.vue')
      },
       // 绑定用户
       {
        path: 'bind-user',
        name: 'bind-user',
        meta:{
          title:'用户绑定',
          requiresAuth: true

        },
        component: () => import(/* webpackChunkName: "assembly" */ '@/pages/assembly/bindUser.vue')
      },
      
      
    ]
  },
  // 单页 人员签到
 // 签到
 {
  path: '/user-signin',
  name: 'user-signin',
  component: () => import(/* webpackChunkName: "signIn" */ '@/pages/userSignin/index.vue')
},


  {
    path: '/realName',
    name: 'realName',
    component: () => import(/* webpackChunkName: "realName" */ '@/pages/realName/index.vue')
  },
  // 报名
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/pages/register/index.vue')
  },
  // // 签名
  // {
  //   path: '/writeName',
  //   name: 'writeName',
  //   meta:{
  //     title:'签名',
  //     requiresAuth: true

  //   },
  //   component: () => import(/* webpackChunkName: "assembly" */ '@/pages/assembly/writeName.vue')
  // },
]