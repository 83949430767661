import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vant from 'vant';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';
// import Vconsole from 'vconsole';




import {
  Button, Input, Form, FormItem,
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/index.scss'

Vue.use(Vant);
Vue.use(Lazyload);
Vue.component(Button.name, Button);
Vue.component(Input.name, Input);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);


// const vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
